// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.avatar {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    flex-shrink: 0; }
    .avatar.avatar-xs, .avatar.avatar-sm {
      width: 2.25rem;
      height: 2.25rem; }
    .avatar.avatar-lg {
      width: 4.5rem;
      height: 4.5rem; }
    .avatar.avatar-xl {
      width: 10.5rem;
      height: 10.5rem; }